import { Link, NavLink, Navigate } from "react-router-dom";
import Logo from "./../../assets/images/icons/logo.svg";
import "./Header.css";
import { useEffect, useState } from "react";
import menuicon from "../../assets/images/menu icon.svg";
import { useNavigate } from "react-router-dom";
import Hamburger from "hamburger-react";
export default function Navbar() {
  const navigate = useNavigate();

  const contactpage = () => {
    navigate("/contact");
  };

  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    const root = document.documentElement;
    if (isNavOpen) {
      root.classList.add("nav-open");
    } else {
      root.classList.remove("nav-open");
    }
  }, [isNavOpen]);

  return (
    <header className="header">
      <div className="container">
        <div className="header-inner">
          <Link to="/" className="brand-logo">
            <img
              src={Logo}
              alt="Oji"
              width={86}
              height={40}
              className="object-fit-contain"
            />
          </Link>
          <nav className="header-nav">
            <NavLink to="/app" className={"nav-link"}>
              Our App
            </NavLink>
            <NavLink to="/about" className={"nav-link"}>
              About Us
            </NavLink>
            <NavLink to="/pmwani" className={"nav-link"}>
              PM-WANI
            </NavLink>
            <button
              onClick={contactpage}
              className="button button-rounded button-bordered text-uppercase"
            >
              Contact Us
            </button>
          </nav>

          <div className="ham" onClick={() => setIsNavOpen(!isNavOpen)}>
            <Hamburger
              toggled={isNavOpen}
              toggle={setIsNavOpen}
              color={"white"}
              size={46}
              duration={0.4}
              rounded={true}
            />
            {/* <img src={menuicon} style={{ width: "50px" }} alt="" /> */}
          </div>
        </div>
      </div>
    </header>
  );
}
