import React from 'react'
import contactfname from "../assets/images/contactfname.svg"
import contactphone from "../assets/images/contactphone.svg"
import contactmail from "../assets/images/contactmail.svg"
const ContactForm = () => {
    return (
        <section className="contact_section">
            <div className="contact_wrapper">
                <div className="left">
                    <h1>Contact Form</h1>
                    <form action="">
                        <div className="top">
                            <div className="firstName">
                                <label htmlFor="firstName">First Name</label>

                                <div className="input_wrapper">
                                    <img src={contactfname} alt="" />
                                    <input type="text" name="firstName" className='firstName' /></div>
                            </div>
                            <div className="phoneNo">
                                <label htmlFor="phoneNo">Phone No.</label>
                                <div className="input_wrapper">
                                    <img src={contactphone} alt="" /><input type="text" name="phoneNo" className='phoneNo' /></div>
                            </div>
                        </div>

                        <div className="email">
                            <label htmlFor="email">E-mail</label>
                            <div className="input_wrapper">
                                <img src={contactmail} alt="" /><input type="text" name="email" className='email' /></div>
                            <label htmlFor="message">Your Message</label>
                            <textarea rows="4" name="message"></textarea>
                        </div>
                        <input
                            type="submit"
                            className="submitBtn"
                            value={"Send Message"}
                        />
                    </form>
                </div>
                <div className="right">


                    <div className="mapouter">
                        <div className="gmap_canvas">
                            <iframe
                                width="520"
                                height="480"
                                id="gmap_canvas"
                                src="https://maps.google.com/maps?q=LAXMI%20PLAZA,%20LAXMI%20IND%20ESTATE,%20NEW%20LINK%20ROAD,%20ANDHERI%20WEST,%20Mumbai%20City%20MH%20400053%20IN&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                frameBorder="0"
                                scrolling="no"
                                marginHeight="0"
                                marginWidth="0"
                                title="Google Map"
                            ></iframe>
                            <a href="https://123movies-to.org"></a>
                        </div>
                        <style>
                            {`
      .mapouter {
        position: relative;
        text-align: right;
        height: 490px;
        width: 520px;
      }
      .gmap_canvas {
        overflow: hidden;
        background: none!important;
        height: 490px;
        width: 520px;
      }
    `}
                        </style>
                        <a href="https://www.embedgooglemap.net"></a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm