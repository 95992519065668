import React from "react";
import Navbar from "./header/Header";
import Footer from "./footer/Footer";
import "./layout.scss"

export default function Layout({ children }) {
  return (
    <div className="Layout">
      <Navbar />
      <div className="layout_children_wrapper" >
        <div className="layout_children" >
        {children}
        </div>
      </div>

      
      <Footer/>
    </div>
  );
}
