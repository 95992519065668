import React from 'react'
import "./TermsAndCondition.scss"
import Layout from '../../components/Layout'
import ContactForm from '../../components/ContactForm'

const TermsAndCondition = () => {
    return (
        <Layout>
            <div className="TermsANDcondition">
                <div className='TermsANDconditionTCON'>

                    <h1>Privacy Policy for OJI</h1>
                    <br />
                    <p>At OJI, accessible from
                        <a href=" https://ojihub.com/"> https://ojihub.com/</a>
                        , the privacy of our visitors is of utmost importance. This Privacy Policy document outlines the types of information collected and recorded by OJI and how we use it.
                        If you have additional questions or require more information about our Privacy Policy, please do not hesitate to contact us.
                        This Privacy Policy applies only to our online activities and is valid for visitors to our website regarding the information they share and/or collect on OJI. This policy is not applicable to any information collected offline or via channels other than this website.
                    </p>
                 
                    <h2>Consent        </h2>
                    <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
                    <h2>Information we collect        </h2>
                    <p>The personal information you are asked to provide, and the reasons for providing it, will be made clear to you at the point we ask for your information.
                        If you contact us directly, we may receive additional information about you, such as your name, email address, phone number, the contents of the message and/or attachments you send us, and any other information you choose to provide.
                        <br />
                        When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
                    </p>

                    <h2>How we use your information        </h2>


                    <ul>
                        <li>   We use the information we collect in various ways, including to: </li>
                        <li>Provide, operate, and maintain our website</li>
                        <li>Improve, personalize, and expand our website</li>
                        <li>Understand and analyze how you use our website</li>
                        <li>Develop new products, services, features, and functionality</li>
                        <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                        <li>Send you emails</li>
                        <li>Find and prevent fraud</li>

                    </ul>

                    <br />
                    <br />

                    <h2>Log Files        </h2>
                    <p>OJI follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics.
                        The information collected by log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks.
                        These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
                    </p>

                    <h2>Advertising Partners Privacy Policies        </h2>
                    <p>You may consult this list to find the Privacy Policy for each of the advertising partners of OJI.
                        Third-party ad servers or ad networks use technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on OJI, which are sent directly to users' browsers.
                        They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
                        Note that OJI has no access to or control over these cookies that are used by third-party advertisers.
                    </p>
                    <h2>Third Party Privacy Policies        </h2>
                    <p>OJI's Privacy Policy does not apply to other advertisers or websites.
                        Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information.
                        It may include their practices and instructions about how to opt-out of certain options.
                        You can choose to disable cookies through your individual browser options.
                        To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
                    </p>
                    <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)        </h2>
                    <p>Under the CCPA, among other rights, California consumers have the right to:</p>
                    <br />
                    <br />
                    <ul>
                        <li>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</li>
                        <li>Request that a business delete any personal data about the consumer that a business has collected.</li>
                        <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>
                        <li>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</li>
                    </ul>
                    <h2>GDPR Data Protection Rights        </h2>
                    <p>We would like to make sure you are fully aware of all of your
                        data protection rights. Every user is entitled to the following:</p>
                    <br />
                    <br />
                    <ul>
                        <li>The right to access – You have the right to request copies
                            of your personal data. We may charge you a small fee for this service.</li>
                        <li>The right to rectification – You have the right to request
                            that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</li>
                        <li>The right to erasure – You have the right to request that
                            we erase your personal data, under certain conditions.</li>
                        <li>The right to restrict processing – You have the right to
                            request that we restrict the processing of your personal data, under certain conditions.</li>
                        <li>The right to object to processing – You have the right to
                            object to our processing of your personal data, under certain conditions.</li>
                        <li>The right to data portability – You have the right to
                            request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
                    </ul>
                    <br />
                    <br />
                    <p>If you make a request, we have one month to respond to you.
                        If you would like to exercise any of these rights, please contact us.</p>
                    <h2>Children's Information        </h2>
                    <p>Another part of our priority is adding protection for children
                        while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
                        OJI does not knowingly collect any Personal Identifiable Information from children under the age of 13.
                        If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
                    </p>
                    <h2>Contact Us        </h2>
                    <p>If you have any questions about this Privacy Policy, please contact us:</p>
                    <br />
                    <br />
                    <p>
                        If you have any concerns or questions about our Privacy Policy or any other matter, please contact us through our email at mail@ojihub.com or call us at (+91) 8888760864.
                        This Privacy Policy was last updated on Jan 2, 2024.

                    </p>

                    <br />
                    <br />
                    <br />
                    <br />
                    <h1>
                        Cancellation Policy for OJI
                    </h1>
                    <br />
                    <br />
                    <p>
                        Since OJI offers non-tangible, irrevocable goods, we do not provide cancellation after the product is purchased. Please ensure that you carefully read the product description before making a purchase.
                        If you have any questions or concerns regarding our Cancellation Policy, please contact us through our email at mail@ojihub.com or call us at (+91) 8888760864.
                        <br />This document was last updated on Jan 2, 2024.
                    </p>

                    <br />
                    <br />
                    <br />
                    <br />
                    <h1>Refund Policy for OJI</h1>
                    <br />
                    <br />
                    <p>Since OJI offers non-tangible, irrevocable goods, we do not provide refunds after the product is purchased. Please make sure to carefully read the product description before making a purchase.
                        If you have any questions or concerns regarding our Refund Policy, please contact us through our email at mail@ojihub.com or call us at <br /> (+91) 8888760864. <br />
                        This document was last updated on Jan 2, 2024.</p>

                    <br />
                    <br />
                    <br />
                    <br />

                    <h1>Shipping Policy for OJI</h1>
                    <br />
                    <br />
                    <p>OJI, being a provider of only readable data information, does not offer any shipping services.
                        If you have any questions or concerns regarding our Shipping Policy, please contact us through our email at mail@ojihub.com or call us at <br /> (+91) 8888760864. <br />
                        This document was last updated on  Jan 2, 2024.
                    </p>

                    <br />
                    <br />
                    <br />
                    <br />

                    <h1>Terms and Conditions for OJI</h1>
                    <br />
                    <br />
                    <p>By accessing this website we assume you accept these terms and conditions.
                        Do not continue to use OJI if you do not agree to take all of the terms and conditions stated on this page.
                        The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: “Client”, “You” and “Your” refers to you, the person log on this website and compliant to the Company's terms and conditions.
                        “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves.
                        All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of the provision of the Company's stated services, in accordance with and subject to, prevailing law of India.
                        Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to the same.
                    </p>
                    <h2>Cookies        </h2>
                    <p>We employ the use of cookies. By accessing OJI, you agreed to use cookies in agreement with the OJI's Privacy Policy.
                        Most interactive websites use cookies to let us retrieve the user's details for each visit.
                        Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website.
                        Some of our affiliate/advertising partners may also use cookies.
                    </p>
                    <h2>License        </h2>
                    <p>Unless otherwise stated, OJI and/or its licensors own the intellectual property rights for all material on OJI.
                        All intellectual property rights are reserved. You may access this from OJI for your own personal use subjected to restrictions set in these terms and conditions.
                    </p>
                    <h2>You must not:        </h2>
                    <ul>
                        <li>Republish material from OJI</li>
                        <li>Sell, rent or sub-license material
                            from OJI</li>
                        <li>Reproduce, duplicate or copy material from OJI</li>
                        <li>Redistribute content from OJI</li>
                    </ul>
                    <h2>This Agreement shall begin on the date hereof.        </h2>
                    <p>Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website.
                        OJI does not filter, edit, publish or review Comments prior to their presence on the website.
                        Comments do not reflect the views and opinions of OJI, its agents, and/or affiliates.
                        Comments reflect the views and opinions of the person who posts their views and opinions.
                        To the extent permitted by applicable laws, OJI shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
                        OJI reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.
                    </p>
                    <h2>You warrant and represent that:        </h2>
                    <ul>
                        <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
                        <li>The Comments do not invade any intellectual property right, including without limitation copyright,
                            patent or trademark of any third party;</li>
                        <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
                        <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
                    </ul>
                    <br />
                    <br />
                    <p>You hereby grant OJI a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats, or media.</p>
                    <h2>Hyperlinking to our Content        </h2>
                    <p>The following organizations may link to our Website without prior written approval:</p>
                    <br />
                    <br />
                    <ul>
                        <li>Government agencies;</li>
                        <li>Search engines;</li>
                        <li>News organizations;</li>
                        <li>Online directory distributors (in the same manner as they hyperlink to other listed businesses' websites)</li>
                        <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
                    </ul>
                    <br />
                    <br />
                    <p>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.</p>
                    <br />
                    <br />
                    <p>We may consider and approve other link requests from the following types of organizations:</p>
                    <br />
                    <br />
                    <ul>
                        <li>commonly-known consumer and/or business information sources;</li>
                        <li>Dot-com community sites</li>
                        <li>Associations or groups representing charities</li>
                        <li>Online directory distributors</li>
                        <li>Internet portals</li>
                        <li>Accounting, law, and consulting firms</li>
                        <li>Educational institutions and trade associations</li>
                    </ul>
                    <p>Link requests from these organizations will be approved if we determine that:
                        <br />
                        a. The link does not reflect unfavorably on us or our accredited businesses      <br />
                        b. The organization has no negative records with us      <br />
                        c. The benefit to us from the visibility of the hyperlink compensates for the absence of OJI      <br />
                        d. The link is in the context of general resource information.      <br />
                        These organizations may hyperlink to our website using:      <br />
                        Our corporate name      <br />
                        The uniform resource locator being linked to      <br />
                        Any other description of our website that makes sense within the context and format of content on the linking party’s site.
                        Use of OJI's logo or other artwork for linking is not allowed without a trademark license agreement.

                    </p>


                    <br />
                    <br />

                    <h2>iFrames        </h2>
                    <p>
                        Creating frames around our webpages that alter the visual presentation or appearance of our website is not allowed without prior approval and written permission.
                    </p>
                    <h2>
                        Content Liability
                    </h2>
                    <p>We are not responsible for any content that appears on your website. You agree to protect and defend us against all claims arising on your website. No link(s) should appear on any website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of any third-party rights.</p>
                    <h2>Your Privacy        </h2>
                    <p>Please refer to our Privacy Policy.</p>

                    <h2>Reservation of Rights        </h2>
                    <p>We reserve the right to request that you remove all links or any particular link to our Website.
                        You approve to immediately remove all links to our Website upon request.
                        We also reserve the right to amend these terms and conditions and its linking policy at any time.
                        By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.
                    </p>
                    <h2>Removal of links from our website        </h2>
                    <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment.
                        We will consider requests to remove links but we are not obligated to or so or to respond to you directly.
                        We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.
                    </p>
                    <h2>Disclaimer        </h2>
                    <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
                        <br />Limit or exclude our or your liability for death or personal injury <br />
                        Limit or exclude our or your liability for fraud or fraudulent misrepresentation <br />
                        Limit any of our or your liabilities in any way that is not permitted under applicable law <br />
                        Exclude any of our or your liabilities that may not be excluded under applicable law. <br />
                        The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: <br />
                        a. Are subject to the preceding paragraph <br />
                        b. Govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty.
                        As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
                    </p>
                    <br />
                    <br />

                    <h2>Contacting Us</h2>
                    <p>If you wish to contact us regarding any matter related to this Shipping Policy, you can send an email to mail@ojihub.com or call us at (+91) 8888760864.</p>






                </div>
            </div>


            <ContactForm />
        </Layout>

    )
}

export default TermsAndCondition