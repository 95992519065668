import "./Footer.scss";
import ojiIcon from "../../assets/images/ojiicon.svg";
import linkedin from "../../assets/images/linkedin.svg";
import instagram from "../../assets/images/instagram.svg";
import facebook from "../../assets/images/facebook.svg";
import twitter from "../../assets/images/twitter.svg";
export default function Footer() {
  return (
    <footer>
      <div className="top">
        <div className="left">
          <div className="text_wrapper">
            <img src={ojiIcon} alt="" />
            <p>
              A Dewin Solution Pvt Ltd initiative,we are driven by our vision to
              contributeto the digital transformation of India.
            </p>
          </div>
          <div className="social_media">
            <a href="https://www.linkedin.com/company/oji-hub/">
              <img src={linkedin} alt="" />
            </a>
            <a href="https://www.instagram.com/oji.hub/">
              <img src={instagram} alt="" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61550350812730">
            <img src={facebook} alt="" />
            </a>
            <a href="https://twitter.com/OjiHub">
              <img src={twitter} alt="" />
            </a>
          </div>
        </div>
        <div className="right">
          <div className="links">
            <a href="">
              <h3>Become A PDO</h3>
            </a>
            <a href="">
              <h3>OJI APP</h3>
            </a>
            <a href="">
              <h3>About Us </h3>
            </a>
            <a href="">
              <h3>PM-WANI</h3>
            </a>
          </div>
          <div className="contacts">
            <p>+91 8888760864 </p>
            <p>515, Laxmi Plaza,Laxmi Industrial Estate,Sab Tv Lane,New  Link Road, Andheri West Mumbai -400053</p>
            <p>mail@ojihub.com</p>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="left">©Copyright 2023 -  Dewin Solutions Private Limited |  Trademarked by Ascent | All Rights Reserve</div>
     
        <div  style={{cursor:"pointer"}} className="right">
          <a style={{cursor:"pointer"}} href="/terms-and-conditions">Terms and Conditions</a>|<a href="">Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
}
