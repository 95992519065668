import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home/Home";
import "./App.css"
import OjiApp from "./pages/ojiApp/ojiApp";
import "./scss/main.scss"
import About from "./pages/about/about";
import Contact from "./pages/contact/contact";
import Pmwani from "./pages/pmwani/pmwani";
import { useEffect } from "react";
import TermsAndCondition from "./pages/termsandcondition/TermsAndCondition";

function App() {
  const {pathname} = useLocation()
  useEffect(()=>{
    window.scrollTo(0,0)
  },[pathname]) 
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/app" element={<OjiApp/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/pmwani" element={<Pmwani/>} />
        <Route path="/terms-and-conditions" element={<TermsAndCondition/>} />

        {/* <Route path="about" element={ <About/> } />
        <Route path="contact" element={ <Contact/> } /> */}
      </Routes>
    </div>
  );
}

export default App;
