import Layout from "../../components/Layout";
import applanding_vector from "../../assets/images/applanding_vector.svg";
import applanding1 from "../../assets/images/applanding1.svg";
import applanding2 from "../../assets/images/applanding2.svg";
import pdoButton from "../../assets/images/pdoButton.svg";
import wificard from "../../assets/images/wifi_card.svg"
import wifiIcon from "../../assets/images/wifiIcon.png"
import marketimg2 from "../../assets/images/supermarketimg2.png"
import googleplay from "../../assets/images/Google Play.svg"
import appstore from "../../assets/images/App Store.svg"
import featuresList from "../../assets/images/featuresList.svg"
import tryButton from "../../assets/images/tryButton.svg"
import features_right from "../../assets/images/features_right.svg"
import boxicon1 from "../../assets/images/boxicon1.svg"
import boxicon2 from "../../assets/images/boxicon2.svg"
import boxicon3 from "../../assets/images/boxicon3.svg"
import boxicon4 from "../../assets/images/boxicon4.svg"
import franchise_left from "../../assets/images/franchise_left.svg"
import franchise_rightpeople from "../../assets/images/franchise_rightpeople.svg"
import partnerButton from "../../assets/images/partnerButton.svg"
import faqLeft from "../../assets/images/faqLeft.svg"
import map from "../../assets/images/map.svg";
import featuresvid from "../../assets/videos/OJI HUB/Features.mp4"
import franchisevid from "../../assets/videos/OJI HUB/Franchise Model - Section Animation.mp4"
import Accordion from "../../components/Accordion.js";
import applandingvid from "../../assets/videos/OJI HUB/OJI APP.mp4"
import card1 from "../../assets/images/card1.png"
import card2 from "../../assets/images/card2.png"
import "./oji.scss";
import ContactForm from "../../components/ContactForm";

const questionsAnswers = [
  {
    question: "What is PM-WANI?",
    answer:
      "You can invite up to 2 additional users on the Free plan. There is no limit on team members for the Premium plan.",
  },
  {
    question: "Who can become a PDO?",
    answer:
      "No more than 2GB. All files in your account must fit your allotted storage space.",
  },
  {
    question: "Advantage of becoming a PDO?",
    answer: `Click “Forgot password” from the login page or “Change password” from your profile page. A reset link will be emailed to you.`,
  },
  {
    question: "How do I become a PDO?",
    answer: `Yes! Send us a message and we’ll process your request no questions asked.`,
  },
  {
    question: "How much investment is required to become a PDO?",
    answer: `Chat and email support is available 24/7. Phone lines are open during normal business hours.`,
  },
  {
    question: "Why will someone buy WIFI coupons when already subscribed to mobile data (3g/4g/5g)",
    answer: `Chat and email support is available 24/7. Phone lines are open during normal business hours.`,
  },
  {
    question: "Can i use my home broadband connection as a backhul for my PDO",
    answer: `Chat and email support is available 24/7. Phone lines are open during normal business hours.`,
  },
  {
    question: "What is the income of PDO?",
    answer: `Chat and email support is available 24/7. Phone lines are open during normal business hours.`,
  },
];

const OjiApp = () => {
  return (
    <Layout>
      <section className="app_landing_section">
        <div className="app_landing_wrapper">
          <div className="text_wrapper">
            <h1>
              Unlocking Efficiency and Growth with the{" "}
              <span>OJI App for PDOs</span>
            </h1>
            <p>
              At OJI, we've developed the OJI app to simplify operations, boost
              transparency, and empower PDOs in managing essential supplies
              efficiently
            </p>
          </div>

          <div className="vectors_wrapper">
          <video muted playsInline loop autoPlay>
              <source src={applandingvid} type="video/mp4" />
            </video>
            {/* <div className="left_right">
              <img src={applanding1} className="applanding1" alt="" />
              <img src={applanding2} className="applanding2" alt="" />
            </div>
            <div className="middle">
              <img
                src={applanding_vector}
                className="applanding_vector"
                alt=""
              />
           
            </div> */}

            <button>
                <img src={pdoButton} alt="" />
              </button>
          </div>
        </div>
      </section>
      <section className="playstore_section">
        <div className="playstore_wrapper">
          <div className="text_wrapper">
            <h3>
              OJI app essentially facilitates a WIFI service, which can <br />be used
              to deliver the following services
            </h3>
          </div>
          <div className="vectors_wrapper">
            <div className="top">
                <div className="card1">
                 {/* <div className="container">
                    <h3>Free Wi-Fi Scheme to Transform Tech World</h3>
                    <img src={wifiIcon} alt="" />
                 </div>
                <img src={wificard} alt="" /> */}
                <img src={card1} alt="" />
                </div>
                <div className="card2">
                {/* <div className="container">
                    <h3>Become a PDO & Start Earning</h3>
                    <img src={marketimg2} className="marketimg2" alt="" />
                 </div>
                <img src={wificard} alt="" /> */}
                <img src={card2} alt="" />
                </div>
            </div>
            {/* <div className="bottom">
                <img src={googleplay} alt="" />
                <img src={appstore} alt="" />
            </div> */}
          </div>
        </div>
      </section>
      <section className="features">
        <div className="features_wrapper">
            <div className="left">
                <img src={featuresList} alt="" className="featuresList" />
                <button>
                <img src={tryButton} alt="" className="tryButton"/>
                </button>
                   
            </div>
            <div className="right">
            <video muted playsInline loop autoPlay>
              <source src={featuresvid} type="video/mp4" />
            </video>
                {/* <img src={features_right} alt="" /> */}
            </div>
        </div>
      </section>
      <section className="benefits">
        <div className="benefits_wrapper">
        <div className="text_wrapper">
    <h1> Benefits of OJI App to PDO</h1>
        </div>
        <div className="box_wrapper">
        <div className="box">
        <img src={boxicon1} alt="" />
            <h3>Recharge Tracking</h3>
            <p>With OJI App, PDOs can effortlessly track the number of recharges made through their devices, ensuring precise financial insights.</p>

            </div>
            <div className="box">
            <img src={boxicon2} alt="" />
            <h3>Plan Purchase Insights</h3>
            <p>Gain valuable insights into the plans purchased by users through your devices, helping you tailor services to meet their specific needs.</p>

            </div>
            <div className="box">
            <img src={boxicon3} alt="" />
            <h3>Real-time User Tracking</h3>
            <p>Stay up-to-date with real-time online user data, allowing PDOs to better serve their beneficiaries</p>

            </div>
            <div className="box">
            <img src={boxicon4} alt="" />
            <h3>Clear Earnings Overview</h3>
            <p>OJI App provides PDOs with a clear and detailed overview of their earnings, empowering them to make informed decisions.</p>

            </div>
        </div>
        </div>

        <div className="franchise_prewrapper">
          <div className="franchise_wrapper">
              <div className="left">
                  <img src={franchise_left} className="franchise_left_img" alt="" />
                  <button><img src={partnerButton} alt="" /></button>
              </div>
              <div className="right">
              <video muted playsInline loop autoPlay>
                <source src={franchisevid} type="video/mp4" />
              </video>
                  {/* <img src={franchise_rightpeople} className="franchise_rightpeople" alt="" /> */}
              </div>
          </div>
        </div>
      </section>
      <section className="faq">
        <div className="faq_wrapper">
       
          <div className="left">
          <h1>FAQ</h1>

            <img src={faqLeft} alt="" />
          </div>
          <div className="right">
            <div className="container">
            <Accordion questionsAnswers={questionsAnswers} />
            </div>
          </div>

        </div>
      </section>
      <ContactForm/>
      {/* <section className="contact_section">
        <div className="contact_wrapper">
          <div className="left">
            <h1>Contact Form</h1>
            <form action="">
              <div className="top">
                <div className="firstName">
                  <label htmlFor="firstName">First Name</label>
                  <input type="text" name="firstName" />
                </div>
                <div className="phoneNo">
                  <label htmlFor="phoneNo">Phone No.</label>
                  <input type="text" name="phoneNo" />
                </div>
              </div>

              <div className="email">
                <label htmlFor="email">E-mail</label>
                <input type="text" name="email" />
                <label htmlFor="message">Your Message</label>
                <textarea rows="4" name="message"></textarea>
              </div>
              <input
                type="submit"
                className="submitBtn"
                value={"Send Message"}
              />
            </form>
          </div>
          <div className="right">
            <img src={map} alt="" />
          </div>
        </div>
      </section> */}
    </Layout>
  );
};

export default OjiApp;
