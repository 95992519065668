import "./contact.scss";
import Layout from "../../components/Layout";
import contact_map from "../../assets/images/contact_map.svg";
import pdoButton from "../../assets/images/pdoButton.svg"
import partnerButton from "../../assets/images/partnerButton.svg"
import faqLeft from "../../assets/images/faqLeft.svg"
import Accordion from "../../components/Accordion.js";
import map from "../../assets/images/map.svg";
import ContactForm from "../../components/ContactForm";


const questionsAnswers = [
    {
      question: "What is PM-WANI?",
      answer:
        "You can invite up to 2 additional users on the Free plan. There is no limit on team members for the Premium plan.",
    },
    {
      question: "Who can become a PDO?",
      answer:
        "No more than 2GB. All files in your account must fit your allotted storage space.",
    },
    {
      question: "Advantage of becoming a PDO?",
      answer: `Click “Forgot password” from the login page or “Change password” from your profile page. A reset link will be emailed to you.`,
    },
    {
      question: "How do I become a PDO?",
      answer: `Yes! Send us a message and we’ll process your request no questions asked.`,
    },
    {
      question: "How much investment is required to become a PDO?",
      answer: `Chat and email support is available 24/7. Phone lines are open during normal business hours.`,
    },
    {
      question: "Why will someone buy WIFI coupons when already subscribed to mobile data (3g/4g/5g)",
      answer: `Chat and email support is available 24/7. Phone lines are open during normal business hours.`,
    },
    {
      question: "Can i use my home broadband connection as a backhul for my PDO",
      answer: `Chat and email support is available 24/7. Phone lines are open during normal business hours.`,
    },
    {
      question: "What is the income of PDO?",
      answer: `Chat and email support is available 24/7. Phone lines are open during normal business hours.`,
    },
  ];
const Contact = () => {
  return (
    <Layout>
      <section className="contact">
        <div className="contact_wrapper">
          <div className="top">
            <div className="container">
              <h1>
                Seeking an expert? Feel free to provide your contact
                information, and
                <span> we'll get in touch with you shortly</span>
              </h1>
              <button>Contact Us: 8888760864</button>
            </div>
          </div>
          <div className="middle">
            <img src={contact_map} alt="" />
          </div>

          <div className="bottom">
            <div className="left">
              <div className="container_answer">
                  <h1>Join the OJI <br /> as PDO</h1>
                  <div className="features_wrapper">
                    <div className="feature">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                      >
                        <path
                          d="M2 4.63043L7.92105 10.5L17 1.5"
                          stroke="#EDFBF9"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <h3>Expand your network</h3>
                    </div>
                    <div className="feature">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                      >
                        <path
                          d="M2 4.63043L7.92105 10.5L17 1.5"
                          stroke="#EDFBF9"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <h3>Grow your brand</h3>
                    </div>
                    <div className="feature">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                      >
                        <path
                          d="M2 4.63043L7.92105 10.5L17 1.5"
                          stroke="#EDFBF9"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <h3>Access more resources</h3>
                    </div>
                   
                  </div>
                  <img src={pdoButton} alt="" />
                   
              </div>
            </div>
            <div className="right">
            <div className="container_answer">
                <h1>Join the OJI franchise  <br /> model</h1>
          
              <div className="features_wrapper">
                <div className="feature">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                  >
                    <path
                      d="M2 4.63043L7.92105 10.5L17 1.5"
                      stroke="#EDFBF9"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <h3>Expand your network</h3>
                </div>
                <div className="feature">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                  >
                    <path
                      d="M2 4.63043L7.92105 10.5L17 1.5"
                      stroke="#EDFBF9"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <h3>Grow your brand</h3>
                </div>
                <div className="feature">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                  >
                    <path
                      d="M2 4.63043L7.92105 10.5L17 1.5"
                      stroke="#EDFBF9"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <h3>Access more resources</h3>
                </div>
        
              </div>
              <img src={partnerButton} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faq">
        <div className="faq_wrapper">
       
          <div className="left">
          <h1>FAQ</h1>

            <img src={faqLeft} alt="" />
          </div>
          <div className="right">
            <div className="container">
            <Accordion questionsAnswers={questionsAnswers} />
            </div>
          </div>

        </div>
      </section>
      <ContactForm/>
    </Layout>
  );
};

export default Contact;
